import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/helpers/seo';

const Error = () => (
  <Layout>
    <SEO title="Page non-trouvée" />
    <h1>Page non-trouvée</h1>
  </Layout>
);

export default Error;
